import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/crm/company'

export default {
  ...apiHelper(API_URL),
  autocomplete: search => API.get(`${API_URL}/autocomplete?query=${search}`),
  getDataFeed: (query = { page: 0 }) => API.get(`/api/data_feed/crm/company?${toQuery(query)}`),
  getCustomFields() {
    return API.get(`${API_URL}/custom_fields`)
  },
  getHistory(
    id,
    payload = {
      size: 25,
    },
  ) {
    return API.get(`${API_URL}/${id}/history?${toQuery(payload)}`)
  },
}
