<template>
  <div class="row-flex-justify-center tw-pt-3 input-group input-group-merge mb-2" style="width: 100%">
    <!-- ALL override styling found in style.scss -->
    <date-picker
      v-model="range"
      type="date"
      :show-week-number="true"
      range
      format="YYYY-MM-DD"
      value-type="format"
      :placeholder="$t(placeholder)"
      input-class="b-form-timepicker form-control"
      @input="$emit('applyDateFilter', range, index)"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: { DatePicker },
  props: {
    placeholder: {
      type: String,
      default: 'Select date range',
    },
    index: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      range: [],
    }
  },
  methods: {
    handleSelect() {
      if (this.index) {
        this.$emit('applyDateFilter', this.range, this.index)
      } else this.$emit('applyDateFilter', this.range, this.index)
    },
  },
}
</script>
