<template>
  <b-row
    v-if="filters.length"
    align-v="center"
  >
    <b-col cols="12">
      <span class="tw-text-base text-body">{{ $t('Filters') }}</span>
    </b-col>
    <b-col
      v-if="currentRouteName === 'inquiries'"
      class="tw-mb-2"
      cols="12"
      sm="4"
      lg="3"
    >
      <assigned-user-filter @handleUserFilter="handleUserFilter" />
    </b-col>
    <b-col
      v-for="(filter, index) in filters"
      :key="index"
      class="tw-mb-2"
      cols="12"
      sm="4"
      lg="3"
    >
      <div class="row-flex-justify-center">
        <custom-input-field
          v-if="filter.type !== 'DATE'"
          :data="filter"
          style="width: 100%"
          :is-lists="true"
          :is-widget="false"
          @fieldError="setError(index, $event)"
          @input="setVal(index, $event), (filter.endDate = null)"
        />
        <date-range-filter
          v-else
          :data="filter"
          :index="index"
          :placeholder="filter.label"
          style="width: 100%"
          @applyDateFilter="applyDynamicDateFilter"
        />
      </div>
    </b-col>

    <b-col cols="12">
      <b-button
        id="create-button"
        variant="primary"
        :class="{
          'icon-disabled': !canApplyFilter,
        }"
        @click="canApplyFilter && applyFilter()"
      >
        <feather-icon
          icon="FilterIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Apply Filter') }}</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import CustomInputField from '@/components/Dashboard/CustomInputField.vue'
import DateRangeFilter from './DateRangeFilter.vue'
import AssignedUserFilter from '@/views/pages/Inquiries/AssignedUserFilter.vue'

export default {
  components: { CustomInputField, DateRangeFilter, AssignedUserFilter },
  props: {
    filterOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedFilter: '',
      filters: [],
      assignedTo: null,
      filterValue: null,
    }
  },
  computed: {
    canApplyFilter() {
      return this.filters.every(filter => filter.error === true)
    },
    currentRouteName() {
      return this.$route.name
    },
  },

  watch: {
    filterOptions: {
      handler() {
        this.filters = this.filterOptions.map(item => {
          if (item.type === 'DATE') item.endDate = null
          return {
            ...item,
            key: this.camelizeGlobal(item.key),
            error: true,
            value: null,
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    setVal(index, val) {
      this.filters[index].value = val
    },
    handleUserFilter(userFilter) {
      // this.filters.value = userFilter
      this.assignedTo = userFilter ? userFilter.userUuid : ''
    },
    setError(index, val) {
      this.filters[index].error = val
    },
    async applyDynamicDateFilter([startDate, endDate], index) {
      this.filters[index].value = {
        startDate,
        endDate,
      }
    },
    async applyFilter() {
      const queryForm = {}
      if (this.assignedTo) {
        queryForm.assignedUserUuid = `='${this.assignedTo}'`
      }
      this.filters.forEach(filter => {
        // TODO: Date format not yet done by BE

        if (filter.value) {
          if (filter.type === 'DATE') {
            queryForm[`${filter.key}_end`] = filter.value.endDate
            queryForm[`${filter.key}_start`] = filter.value.startDate
          }

          if (filter.type === 'PHONE') {
            queryForm[filter.key] = `='${filter.value.toString()}'`
          }

          if (filter.type === 'PERCENT') {
            const percent = filter.value.toString() / 100
            queryForm[filter.key] = `=${percent.toFixed(4)}`
          }

          if (filter.type === 'NUMBER') {
            queryForm[filter.key] = `=${filter.value.toString()}`
          }

          if (filter.type === 'BOOL') {
            queryForm[filter.key] = `=${filter.value}`
          }

          if (filter.type === 'TEXT' || filter.type === 'LONGTEXT') {
            queryForm[filter.key] = `ilike '%${filter.value.toLowerCase()}%'`
          }

          if (filter.type === 'MULTISELECT') {
            queryForm[filter.key] = filter.value.length ? `ilike '%${filter.value.join(', ')}%'` : ''
          }
          if (filter.type === 'LIST' && filter.value) {
            queryForm[filter.key] = `ilike '%${filter.value.toString()}%'`
          }

          if (filter.type === 'URL' || filter.type === 'EMAIL') {
            queryForm[filter.key] = `='${filter.value}'`
          }
        }
      })
      this.$emit('applyFIlter', queryForm)
    },
  },
}
</script>

<style>
::placeholder {
  color: var(--colour--input--placeholder);
}
</style>
