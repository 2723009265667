var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type !== 'list' && _vm.type !== 'bool' && _vm.type !== 'date' && _vm.type !== 'multiselect')?_c('prozess-input',{attrs:{"placeholder":_vm.$t(_vm.$case.title(_vm.data.label || _vm.placeholder)),"field":_vm.data?.field?.paramValue ?? _vm.data?.field?.fieldKey ?? null,"icon":_vm.customFieldTypeIcons[_vm.type.toUpperCase()],"name":_vm.data?.field?.paramValue ?? _vm.data?.field?.fieldKey ?? null,"error":typeof _vm.errorMessage !== 'boolean' ? _vm.errorMessage : '',"type":_vm.type === 'phone' ? 'text' : _vm.inputType[_vm.type]},on:{"input":_vm.validate},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}):(_vm.type === 'bool' && !_vm.isWidget)?_c('b-form-checkbox',{staticClass:"tw-mb-4",model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$case.title(_vm.data.label)))+" ")]):_vm._e(),(_vm.isWidget && _vm.type === 'bool')?_c('prozess-input',{attrs:{"disabled":"","placeholder":_vm.$t(_vm.$case.title(_vm.data.label))},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}):(_vm.type === 'date')?_c('div',{staticClass:"tw-pt-3"},[_c('prozess-field-wrapper',[_c('prozess-date-picker',{attrs:{"date-format-options":_vm.isLists
            ? { year: 'numeric', month: 'numeric', day: 'numeric' }
            : {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              weekday: 'long',
            },"locale":_vm.$i18n.locale,"placeholder":_vm.$t(_vm.$case.title(_vm.data.label))},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)],1):_vm._e(),(_vm.type === 'list' || _vm.type === 'multiselect')?_c('div',[_c('b-input-group',{staticClass:"tw-pb-3 input-group-merge form-sidebar__add-user"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":_vm.customFieldTypeIcons[_vm.type.toUpperCase()]}})],1),_c('prozess-select',{staticStyle:{"flex":"1"},attrs:{"multiple":_vm.type === 'multiselect',"placeholder":_vm.$t(_vm.$case.title(_vm.data.label)),"options":_vm.data.details ? _vm.data.details.values : _vm.data.values,"label":_vm.$t(_vm.$case.title(_vm.data.label))},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }