import inquiryService from '@/services/inquiry'
import fields from '@/mixins/fields'

export default {
  data() {
    return {
      metastatusLoading: false,
      statusOptions: [],
      priorityOptions: [],
      categoryOptions: [],
      sourceOptions: [],
    }
  },
  mixins: [fields],
  methods: {
    async getMetastatusInitialData() {
      const isCrm = ['contact-view', 'company-view'].includes(this.$route.name)

      try {
        this.metastatusLoading = true
        const [statusRes, priorityRes, categoryRes, sourceRes] = await Promise.all([
          inquiryService.getOneMetaStatus(this.getSchemaTableName.schemaKey, this.getSchemaTableName.tableKey),
          inquiryService.searchMetastatus('priority', isCrm),
          inquiryService.searchMetastatus('category', isCrm),
          inquiryService.searchMetastatus('source', isCrm),
        ])

        if (this.$route.query.statusId) this.$refs.statusFilter.statusId = parseInt(this.$route.query.statusId)
        this.statusOptions = statusRes.data.pageItems
        this.priorityOptions = priorityRes.data.pageItems
        this.categoryOptions = categoryRes.data.pageItems
        this.sourceOptions = sourceRes.data.pageItems
      } finally {
        setTimeout(() => {
          this.metastatusLoading = false
        }, 500) // prevent zoom animation glitch that removes border top color
      }
    },
  },
}
