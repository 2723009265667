<template>
  <b-card
    no-body
    class="system-settings-card"
  >
    <div>
      <sidebar-form
        :visible="showForm"
        :status-options="statusOptions"
        :resource-id="resourceId"
        @saved="fetchList()"
        @close="onCloseForm"
      />
      <b-row
        class="mx-2 mt-2"
        align-h="between"
      >
        <b-col
          cols="12"
          lg="5"
        >
          <b-row>
            <b-col
              cols="auto"
              class="mb-1 p-0"
            >
              <b-button
                id="create-button"
                variant="primary"
                class="w-100"
                :class="{ 'icon-disabled': $can('Create', 'CRM') === false }"
                @click="$can('Create', 'CRM') && create()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle text-nowrap">
                  {{ $t('Create New') }}
                </span>
              </b-button>
              <b-tooltip target="create-button">
                {{ $can('Create', 'CRM') === true ? $t('Create') : $t('You dont have permission to create company') }}
              </b-tooltip>
            </b-col>
            <b-col
              class="mb-1"
              cols="auto"
            >
              <imex-actions-dropdown
                :schema-name="Schema.CRM"
                :entity-type="Entity.COMPANY"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          lg="7"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
              lg="4"
              class="mb-1"
            >
              <prozess-search-input
                class="tw-mr-4"
                :value="search"
                :placeholder="$t('Search')"
                style="width: 100%"
                @input="handleSearch"
              />
            </b-col>
            <b-col
              cols="12"
              lg="4"
              md="4"
              class="mb-1"
            >
              <status-filter
                ref="statusFilter"
                :value="statusId"
                :status-options="statusOptions"
                style="width: 100%"
                class="mb-n2"
                @statusSelected="handleStatusFilter"
              />
            </b-col>
            <b-col
              cols="12"
              lg="4"
              md="4"
              class="mb-1"
            >
              <date-range-filter
                ref="dateRangeFilter"
                style="width: 100%; padding-top: 1px;"
                @applyDateFilter="applyDateFilter"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="m-2">
        <user-group-filter
          ref="userGroupFilter"
          @handleUserFilter="handleUserFilter"
          @handleGroupFilter="handleGroupFilter"
          @handleFilterByCurrentUser="handleFilterByCurrentUser"
        />
      </div>
      <div class="m-2">
        <filter-input
          :filter-options="filterOptions"
          @applyFIlter="applyFIlter"
        />
      </div>
      <div
        v-if="!customizeLoading"
        class="m-2"
      >
        <b-table
          ref="refTable"
          :busy="loading"
          :items="items"
          responsive
          :is-customizable="true"
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
          no-local-sorting
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(statusName)="{ item }">
            <span>{{ item.statusName }}</span>
          </template>

          <template #cell(phone)="{ item }">
            <span class="text-nowrap text-primary">
              {{ item.phone | formatPhone }}
            </span>
          </template>

          <template #cell()="data">
            <router-link
              v-if="isTitle(data)"
              class="text-nowrap"
              :to="{
                name: 'company-view',
                params: {
                  idFeed: data.item.id,
                  id: data.item.uuidKey,
                  tab: GenericTabs.OVERVIEW_360,
                },
              }"
            >
              {{ findValue(data) }}
            </router-link>
            <span
              v-else
              :id="`tooltip-${data.index}-${data.field.key}`"
              style="max-width: 200px"
              class="d-block"
              :class="{ 'text-primary': data.field.key.includes('mobile'), 'text-truncate': data.field.type === 'LONGTEXT' }"
            >
              {{ findValue(data) }}
            </span>
            <b-tooltip
              v-if="data.field.type === 'LONGTEXT'"
              :target="`tooltip-${data.index}-${data.field.key}`"
              :title="findValue(data)"
              placement="down"
            />
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-row-${item.uuidKey}-preview-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': $can('Update', 'CRM') === false,
                }"
                @click="$can('Update', 'CRM') ? edit(item.uuidKey) : null"
              />
              <b-tooltip
                :title="$can('Update', 'CRM') === true ? $t('Edit') : $t('You dont have permission to update companies')"
                :target="`edit-row-${item.uuidKey}-preview-icon`"
                placement="left"
              />
              <feather-icon
                :id="`delete-row-${item.uuidKey}-preview-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': $can('Delete', 'CRM') === false,
                }"
                @click="remove(item.uuidKey, item.version)"
              />
              <b-tooltip
                :title="$can('Delete', 'CRM') === true ? $t('Deactivate') : $t('You dont have permission to deactivate companies')"
                :target="`delete-row-${item.uuidKey}-preview-icon`"
                placement="left"
              />
            </div>
          </template>
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import { Schema, Entity, GenericTabs } from '@/constants/app'
import companyService from '@/services/company'
import listMixinFactory from '@/mixins/list'
import ImexActionsDropdown from '@/views/shared/Imex/ImexActionsDropdown.vue'
import SidebarForm from '@/components/Company/CompanyForm.vue'
import metastatusDropdownMixin from '@/mixins/metastatusDropdown'
import FilterInput from '@/components/Lists/FilterInput.vue'
import StatusFilter from '@/components/Lists/StatusFilter.vue'
import DateRangeFilter from '@/components/Lists/DateRangeFilter.vue'
import UserGroupFilter from '@/components/Lists/UserGroupFilter.vue'

export default {
  components: {
    SidebarForm,
    UserGroupFilter,
    ImexActionsDropdown,
    FilterInput,
    StatusFilter,
    DateRangeFilter,
  },
  filters: {
    formatPhone(value) {
      if (value) {
        if (value.includes('+47')) {
          return value
        }
        return `+47 ${value}`
      }
    },
  },
  mixins: [
    metastatusDropdownMixin,
    listMixinFactory({
      routeName: 'companies',
      isCustomizable: true,
      listFormat: true,
      service: companyService,
      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        {
          key: 'companyNumber',
          label: 'Organization Number',
          class: 'disabled',
        },
        { key: 'country', label: 'Country', class: 'disabled' },
        { key: 'phone', label: 'Phone', class: ' disabled' },
        {
          key: 'numberOfContacts',
          label: 'Contacts',
          class: 'text-center disabled',
        },
        { key: 'actions', class: 'text-center disabled' },
      ],
      searchField: 'name',
      sort: {
        name: 'name',
        label: 'label',
      },
    }),
  ],

  data() {
    return {
      GenericTabs,
      Schema,
      Entity,
    }
  },

  mounted() {
    this.getMetastatusInitialData()
  },

  methods: {
    create() {
      this.resourceId = null
      this.showForm = true
    },
    remove(id, version) {
      if (this.$can('Delete', 'CRM') === false) return
      this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to deactivate?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary text-white ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteResource(id, version)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
