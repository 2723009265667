import { shapeSchemaByMetadata } from '@/helpers/field'

export const companySchema = shapeSchemaByMetadata

export const companyContactSchema = shapeSchemaByMetadata

export const companyContactRelSchema = shapeSchemaByMetadata

export default {
  companySchema,
  companyContactSchema,
  companyContactRelSchema,
}
