import { renameKeys } from 'lodash-contrib'
import { cloneDeep, pick, invert } from 'lodash'

export const standardKeyMapping = {
  address: 'address',
  city: 'city',
  statusId: 'statusId',
  companyNumber: 'company_number',
  country: 'country',
  description: 'description',
  name: 'name',
  phone: 'phone',
  zip: 'zip',
  companyVersion: 'companyVersion',
}

export const formatAsPayload = (form, fieldMetadata) => {
  form = cloneDeep(form)

  const standardFields = pick(
    form,
    Object.values(standardKeyMapping),
  )

  return {
    ...renameKeys(standardFields, invert(standardKeyMapping)),

    customFields: fieldMetadata.reduce((acc, field) => {
      if (field.customField) {
        acc[field.key] = field.type === 'DATE' && !form[field.key] ? null : form[field.key]
      }

      return acc
    }, {}),
  }
}
