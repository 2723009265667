<template>
  <prozess-field-wrapper
    :icon="MetastatusTypeIcons['status']"
    class="input-group-merge table-search"
  >
    <prozess-select
      v-model="statusId"
      style="flex: 1"
      :placeholder="$t('Status')"
      :options="statusOptions"
      :reduce="option => option.metaStatusId"
      label="metaStatusName"
      :clearable="true"
      @input="$emit('statusSelected', statusId)"
    />
  </prozess-field-wrapper>
</template>

<script>
import { MetastatusTypeIcons } from '@/constants/metastatusTypes'

export default {
  props: {
    statusOptions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Array],
      default: null,
    },
  },
  data() {
    return {
      statusId: null,
      MetastatusTypeIcons,
    }
  },
  watch: {
    value: {
      handler() {
        this.statusId = this.value
      },
      immediate: true,
    },
  },
}
</script>

<style></style>
