<template>
  <div>
    <prozess-input
      v-if="type !== 'list' && type !== 'bool' && type !== 'date' && type !== 'multiselect'"
      v-model="input"
      :placeholder="$t($case.title(data.label || placeholder))"
      :field="data?.field?.paramValue ?? data?.field?.fieldKey ?? null"
      :icon="customFieldTypeIcons[type.toUpperCase()]"
      :name="data?.field?.paramValue ?? data?.field?.fieldKey ?? null"
      :error="typeof errorMessage !== 'boolean' ? errorMessage : ''"
      :type="type === 'phone' ? 'text' : inputType[type]"
      @input="validate"
    />
    <b-form-checkbox
      v-else-if="type === 'bool' && !isWidget"
      v-model="input"
      class="tw-mb-4"
    >
      {{ $t($case.title(data.label)) }}
    </b-form-checkbox>

    <prozess-input
      v-if="isWidget && type === 'bool'"
      v-model="input"
      disabled
      :placeholder="$t($case.title(data.label))"
    />

    <div
      v-else-if="type === 'date'"
      class="tw-pt-3"
    >
      <prozess-field-wrapper>
        <prozess-date-picker
          v-model="input"
          :date-format-options="
            isLists
              ? { year: 'numeric', month: 'numeric', day: 'numeric' }
              : {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                weekday: 'long',
              }
          "
          :locale="$i18n.locale"
          :placeholder="$t($case.title(data.label))"
        />
      </prozess-field-wrapper>
    </div>
    <div v-if="type === 'list' || type === 'multiselect'">
      <b-input-group class="tw-pb-3 input-group-merge form-sidebar__add-user">
        <b-input-group-prepend is-text>
          <feather-icon :icon="customFieldTypeIcons[type.toUpperCase()]" />
        </b-input-group-prepend>
        <prozess-select
          v-model="input"
          :multiple="type === 'multiselect'"
          style="flex: 1"
          :placeholder="$t($case.title(data.label))"
          :options="data.details ? data.details.values : data.values"
          :label="$t($case.title(data.label))"
        />
      </b-input-group>
    </div>

    <!-- <span v-if="typeof errorMessage === 'string'" class="invalid-feedback">{{
      $t(errorMessage)
    }}</span> -->
  </div>
</template>

<script>
import CustomFieldTypes, { customFieldTypeIcons, inputType } from '@/constants/customFieldTypes'
import ProzessFieldWrapper from '@core/components/ProzessFieldWrapper.vue'

const validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

const validator = {
  email: val => validEmail.test(val) || 'Must be a valid email',
}

export default {
  components: {
    ProzessFieldWrapper,
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },

    isWidget: {
      type: Boolean,
      defautlt: false,
    },
    placeholder: {
      type: String,
      default: 'Enter a value',
    },
    data: {
      type: Object,
      default: () => {},
    },
    isLists: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      input: '',
      customFieldTypeIcons,
      inputType,
      CustomFieldTypes,
      errorMessage: true,
    }
  },

  computed: {
    type() {
      if (this.data.length) {
        return this.data?.details?.type?.toLowerCase() ?? this.data.field.type.toLowerCase()
      }
      return ''
    },
  },

  watch: {
    data: {
      handler(newVal) {
        if (newVal && !this.isLists) {
          this.input = newVal.paramValue
        }
      },
      immediate: true,
      deep: true,
    },

    input: {
      handler(newVal) {
        this.handleInput(newVal)
      },
    },

    errorMessage: {
      handler(newVal) {
        this.$emit('fieldError', newVal)
      },
      immediate: true,
    },
  },

  methods: {
    handleInput(newVal) {
      this.$emit('input', newVal)
    },

    validate() {
      if (!this.input) {
        this.errorMessage = true
        return false
      }
      try {
        this.errorMessage = validator[this?.type](this.input)
      } catch (err) {
        return false
      }
    },
  },
}
</script>
<style></style>
