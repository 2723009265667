export default {
  STATUS: 'status',
  CATEGORY: 'category',
  SOURCE: 'source',
  PRIORITY: 'priority',
}

export const MetastatusTypeIcons = {
  status: 'ActivityIcon',
  category: 'GridIcon',
  priority: 'ChevronsUpIcon',
  source: 'DatabaseIcon',
}
