<template>
  <div>
    <!-- <span class="tw-text-base text-white">User and group assign filter</span> -->
    <b-row align-v="center">
      <b-col
        class="tw-mb-2"
        cols="12"
        sm="4"
        lg="3"
      >
        <b-input-group class="input-group-merge form-sidebar__add-user">
          <b-input-group-prepend is-text>
            <feather-icon icon="UserPlusIcon" />
          </b-input-group-prepend>
          <v-select
            v-model="selectedUser"
            style="flex: 1"
            :placeholder="$t('Filter user')"
            :options="users"
            :get-option-label="option => option.firstName + ' ' + option.lastName"
            :clearable="true"
            @input="selectUser"
            @search="onSearchUser"
            @search:blur="onBlurSearchUser"
          >
            <span slot="no-options">
              <span v-if="searchUserText.length">{{ $t(`No results found`) }}</span>
              <span v-else>{{ $t(`Search by name`) }}</span>
            </span>
          </v-select>
        </b-input-group>
      </b-col>
      <b-col
        class="tw-mb-2"
        cols="12"
        sm="4"
        lg="3"
      >
        <b-input-group class="input-group-merge form-sidebar__add-user">
          <b-input-group-prepend is-text>
            <feather-icon icon="UserPlusIcon" />
          </b-input-group-prepend>
          <v-select
            v-model="selectedGroup"
            style="flex: 1"
            :placeholder="$t('Filter group')"
            :options="groups"
            :get-option-label="option => option.name"
            :clearable="true"
            @input="selectGroup"
            @search="onSearchGroup"
            @search:blur="onBlurSearchGroup"
          >
            <span slot="no-options">
              <span v-if="searchGroupText.length">{{ $t(`No results found`) }}</span>
              <span v-else>{{ $t(`Search by name`) }}</span>
            </span>
          </v-select>
        </b-input-group>
      </b-col>
      <b-col
        class="tw-mb-2"
        cols="12"
        sm="3"
        lg="2"
      >
        <b-form-checkbox
          v-model="filterByCurrentUser"
          class="tw-mb-4"
          @input="setFilterByCurrentUser"
        >
          {{ $t('Filter by Current User') }}
        </b-form-checkbox>
      </b-col>
      <b-col
        v-if="currentRouteName === 'contacts'"
        class="tw-mb-2"
        cols="12"
        sm="4"
        lg="3"
      >
        <b-form-checkbox
          v-model="filterContactPerson"
          class="tw-mb-4"
          @input="getContactPerson"
        >
          {{ $t('Filter Company Contact') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import employeeService from '@/services/employee'
import groupService from '@/services/group'
import fieldMixins from '@/mixins/fields'

export default {
  components: { vSelect },
  mixins: [fieldMixins],
  data() {
    return {
      users: [],
      groups: [],
      filterContactPerson: false,
      selectedUser: null,
      selectedGroup: null,
      searchUserText: '',
      searchGroupText: '',
      filterByCurrentUser: false,
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  methods: {
    onBlurSearchUser() {
      this.users = []
    },
    onSearchUser(search, loading) {
      this.searchUserText = search
      if (search.length) {
        loading(true)
        this.searchUser(search, loading, this)
      }
    },

    selectUser(data) {
      this.$emit('handleUserFilter', data)
    },

    selectGroup(data) {
      this.$emit('handleGroupFilter', data)
    },

    getContactPerson(data) {
      this.$emit('handleContactPersonFilter', data)
    },

    setFilterByCurrentUser(data) {
       this.$emit('handleFilterByCurrentUser', data)
    },

    searchUser: debounce(async (search, loading, vm) => {
      const res = await employeeService.searchEmployee(search)
      vm.users = res.data
      loading(false)
    }, 500),

    onBlurSearchGroup() {
      this.groups = []
    },
    onSearchGroup(search, loading) {
      this.searchGroupText = search
      if (search.length) {
        loading(true)
        this.searchGroup(search, loading, this)
      }
    },

    searchGroup: debounce(async (search, loading, vm) => {
      const res = await groupService.searchGroup(search)
      vm.groups = res.data
      loading(false)
    }, 500),

    async getGroupData(id) {
      const { data } = await groupService.getOne(id)
      this.selectedGroup = data
    },

    async getUserData(id) {
      const { data } = await employeeService.getOne(id)
      this.selectedUser = data
    },
  },
}
</script>

<style></style>
