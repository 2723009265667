<template>
  <div class="row-flex-justify-center" style="padding-top: 9px;">
    <div style="width: 100%;">
      <b-input-group class="mb-2 input-group-merge form-sidebar__add-user">
        <b-input-group-prepend is-text>
          <feather-icon icon="UserPlusIcon" />
        </b-input-group-prepend>
        <v-select
          v-model="selectedUser"
          style="flex: 1"
          :placeholder="$t('Assigned To')"
          :options="users"
          :get-option-label="option => option.firstName + ' ' + option.lastName"
          :clearable="true"
          @input="selectUser"
        >
          <span slot="no-options">
            <span v-if="searchUserText.length">{{ $t(`No results found`) }}</span>
            <span v-else>{{ $t(`Search by name`) }}</span>
          </span>
        </v-select>
      </b-input-group>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import employeeService from '@/services/employee'
import groupService from '@/services/group'
import fieldMixins from '@/mixins/fields'
import { mapState } from 'vuex'
import settingsService from '@/services/settings'
import inquiryService from '@/services/inquiry'

export default {
  components: { vSelect },
  mixins: [fieldMixins],
  data() {
    return {
      users: [],
      hasUsersGroup: false,
      selectedUser: null,
      selectedGroup: null,
      searchUserText: '',
      searchGroupText: '',
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },
  created() {
    // this.getInitialData()
    this.getUsersWithInquiries()
  },
  methods: {
    onBlurSearchUser() {
      // if (!this.hasUsersGroup) {
      //   this.users = []
      // }
    },
    onSearchUser(search, loading) {
      this.searchUserText = search
      if (search.length) {
        loading(true)
        this.searchUser(search, loading, this)
      }
    },

    selectUser(data) {
      this.$emit('handleUserFilter', data)
    },

    searchUser: debounce(async (search, loading, vm) => {
      const res = await employeeService.searchEmployee(search)
      vm.users = res.data
      loading(false)
    }, 500),

    async getUsersWithInquiries() {
      const { data } = await inquiryService.getUsersWithInquiries()
      const assignedToMe = {
        userUuid: this.user.userUuid,
        firstName: 'Assigned to me',
        lastName: '',
      }
      const users = data.filter(({ userUuid }) => userUuid !== this.user.userUuid)
      this.users = [assignedToMe, ...users]
    },
    async getUserData(id) {
      const { data } = await employeeService.getOne(id)
      this.selectedUser = data
    },
    async getInitialData() {
      this.loading = true
      const { response: settingsResponse } = await this.$async(settingsService.getOne('inquiry'))
      const userGroupId = settingsResponse?.data
      const assignedToMe = {
        userUuid: this.user.userUuid,
        firstName: 'Assigned to me',
        lastName: '',
      }
      this.users = [assignedToMe]
      if (userGroupId) {
        const { response: groupResponse } = await this.$async(groupService.getOne(userGroupId))
        const { members } = groupResponse?.data
        if (members.length) {
          this.users = [assignedToMe, ...members]
          this.hasUsersGroup = true
        }
      }
      this.loading = false
    },
  },
}
</script>

<style></style>
