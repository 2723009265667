<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      id="formSidebar"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <prozess-field-wrapper
        :icon="MetastatusTypeIcons['status']"
        :error="$hasError('statusId')"
      >
        <prozess-select
          v-model="form.statusId"
          style="flex: 1"
          :placeholder="$t('Status')"
          :options="statusOptions"
          :reduce="(option) => option.metaStatusId"
          label="metaStatusName"
          :clearable="false"
        />
      </prozess-field-wrapper>

      <span v-if="$hasError('statusId')" class="invalid-feedback">
        {{ $t($hasError('statusId')) }}
      </span>
      <div v-for="field in filteredDuplicatedField" :key="field.key">
        <AppDynamicField
          v-model="form[field.key]"
          :field="field"
          :error="$hasError(field.key)"
          :resourceId="resourceId"
        />
      </div>
      <form-notification
        :id="resourceId"
        :visible="visible"
        @userAdded="handleSelectedUser"
        @userRemoved="handleUserRemoved"
        @groupAdded="handleSelectedGroup"
        @groupRemoved="handleGroupRemoved"
        @categoryAdded="handleSelectedUser"
      />
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { renameKeys } from 'lodash-contrib'
import { Entity, Schema } from '@/constants/app'
import { MetastatusTypeIcons } from '@/constants/metastatusTypes'
import { companySchema } from '@/schema/company'
import { advancedFieldValue } from '@/helpers/customFields'
import { fillableOnly } from '@/helpers/field'
import { formatAsPayload, standardKeyMapping } from '@/helpers/companyForm'
import { fetchBackendErrors } from '@/helpers/backendValidations'
import advancedFieldMgmtService from '@/services/advancedFieldMgmt'
import companyService from '@/services/company'
import AppDynamicField from '@/components/shared/AppDynamicField.vue'
import CustomFieldTypes from '@/constants/customFieldTypes'
import FormNotification from '@/components/Forms/FormNotification.vue'

const staticField = {
  statusId: 'statusId',
}

export default {
  components: {
    AppDynamicField,
    FormNotification,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      MetastatusTypeIcons,
      CustomFieldTypes,
      errors: [],
      form: {},
      fieldMetadata: [],
      filteredDuplicatedField: [],
      groupList: [],
      loading: true,
      saving: false,
    }
  },

  computed: {
    formTitle() {
      return this.resourceId ? 'Edit Company' : 'Add New Company'
    },
  },

  watch: {
    statusOptions: {
      handler(value) {
        if (value) this.setStatus()
      },
    },
    visible: {
      async handler(visible) {
        if (visible) {
          this.reset()
          await this.getAllFields()
          if (this.resourceId) {
            this.getData()
          }
        }
      },

      immediate: true,
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    handleUserRemoved(userUuid) {
      this.form.userCategoryAssignFormList = this.form.userCategoryAssignFormList.filter(
        user => user.userUuid !== userUuid,
      )
    },

    handleGroupRemoved(groupUuid) {
      this.groupList = this.groupList.filter(
        group => group !== groupUuid,
      )
    },

    handleSelectedUser(userUuid, categoryId) {
      let userList = this.form.userCategoryAssignFormList
      if (!userList) {
        userList = [{
          userUuid,
          categoryId,
        }]
      } else {
        const userIndex = userList.findIndex(item => item.userUuid === userUuid)
        if (userIndex === -1) {
          userList.push({
            userUuid,
            categoryId,
          })
        } else {
          userList[userIndex] = {
            userUuid,
            categoryId,
          }
        }
      }
      this.form.userCategoryAssignFormList = userList
    },

    handleSelectedGroup(groupUuid) {
      this.groupList.push(groupUuid)
    },

    formatAsFormData(response) {
      const customFields = response.customFieldData.reduce((acc, item) => {
        acc[item.key] = advancedFieldValue(item.value, {
          toArrayIfMulti: true,
        })
        return acc
      }, {})

      const form = this.$lodash.pick(response, Object.keys(standardKeyMapping))

      this.form = {
        ...renameKeys(form, standardKeyMapping),
        ...customFields,
      }
    },

    async getAllFields() {
      const { response } = await this.$async(
        advancedFieldMgmtService.getFieldsByEntity({
          schema: Schema.CRM,
          table: Entity.COMPANY,
        }),
      )
      const fieldMetadata = fillableOnly(response.data)
      this.filteredDuplicatedField = fieldMetadata.filter(
        fieldFilter => !Object.values(staticField).some(val => val === fieldFilter.key),
      )
      // Force $set all fields to `form` object to make them reactive for v-models
      fieldMetadata.forEach(field => {
        // console.log(field.type)
        if (field.type === 'BOOL') {
          this.$set(this.form, field.key, false)
        } else this.$set(this.form, field.key, null)
      })

      this.fieldMetadata = this.$lodash.cloneDeep(fieldMetadata)
      this.setStatus()
    },

    setStatus() {
      if (this.resourceId) return
      const statusDefaultValue = this.statusOptions.find(status => status.defaultTo.includes('company'))
      this.form.statusId = statusDefaultValue?.metaStatusId ?? ''
    },

    async getData() {
      this.loading = true

      const { response } = await this.$async(
        companyService.getOne(this.resourceId),
      )

      this.formatAsFormData(response.data)

      this.loading = false
    },

    reset() {
      this.form = {}
      this.errors = []
      this.groupList = []
      this.loading = false
      this.saving = false
    },

    removeEmpty(obj) {
      return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
    },

    async save() {
      const statusValidation = [
        {
          required: true,
          key: 'statusId',
          type: 'TEXT',
        },
      ]
      this.form.last_name = this.form.last_name?.trim()

      const form = this.$lodash.cloneDeep(this.form)

      this.errors = await this.yupValidate(
        companySchema([...this.fieldMetadata, statusValidation]),
        form,
      )

      if (this.errors.length) {
        return
      }

      this.saving = true

      let payload = formatAsPayload(this.form, this.fieldMetadata)
      payload.customFields = this.removeEmpty(payload.customFields)

      if (payload.statusId === 0) {
        delete payload.statusId
      }

      if (form.userCategoryAssignFormList) {
        payload = { ...payload, userCategoryAssignFormList: form.userCategoryAssignFormList }
      }

      if (this.groupList) {
        payload = { ...payload, groupUuidList: this.groupList }
      }

      const request = this.resourceId
        ? companyService.update(this.resourceId, payload)
        : companyService.store({
          ...payload,
          companyVersion: 1,
        })

      const { error } = await this.$async(request)

      if (error) {
        const errorMessage = fetchBackendErrors(error, this.resourceId)
        this.saving = false
        return this.showWarning(errorMessage)
      }

      this.saving = false

      if (!error) {
        this.$emit('saved')
        this.close()
      }
    },
  },
}
</script>
